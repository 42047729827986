<template>
  <label class="option-color__header position-relative" :for="'color-' + option.id">
    <span class="option-color__label">{{ option.name }}</span>
    <img :src="$filters.image(option.image, imageDimensions)" class="option-color__img">
  </label>
  <div class="tooltip-box tooltip-box--price mb-2">
    <tooltip-btn :tooltip="option.tooltip"/>
  </div>
  <div class="option-color__footer">
    <div class="form-check">
      <input
      class="form-check-input"
      type="radio"
      :id="'color-' + option.id"
      :checked="option.selected"
      @click="selectColor(option)">
      <label class="form-check-label" :for="'color-' + option.id">
        {{ option.label }}
      </label>
    </div>
  </div>
</template>
<script>
import { computed } from '@vue/reactivity'
import { useStore } from 'vuex'
import TooltipBtn from '@/pages/configurator/common/tooltipBtn.vue'
export default {
  name: 'ColorOption',
  components: { TooltipBtn },
  props: {
    option: {
      require: true,
      type: Object
    }
  },
  setup () {
    const store = useStore()
    const selectColor = (option) => store.dispatch('configurator/selectColor', option)
    const imageDimensions = computed(() => {
      let str = ''
      const width = 150
      const height = 100
      if (width) {
        str = Number.parseInt(width)
      }
      str += 'x'
      if (height) {
        str += Number.parseInt(height)
      }
      return str
    })
    return {
      imageDimensions,
      selectColor
    }
  }
}
</script>
